<template>
	<el-form
		ref="itemForm"
		class="option-item-container"
		:model="formData"
		:rules="rules"
	>
		<el-form-item :label="itemIndex == 0 ? 'RU' : ''" prop="title_ru">
			<!-- <label v-if="itemIndex == 0">RU</label> -->
			<el-input v-model="formData.title_ru" placeholder="Название"> </el-input>
		</el-form-item>

		<el-form-item :label="itemIndex == 0 ? 'UA' : ''" prop="title_uk">
			<!-- <label v-if="itemIndex == 0">UK</label> -->
			<el-input v-model="formData.title_uk" placeholder="Назва"> </el-input>
		</el-form-item>

		<el-form-item
			v-if="additionalOptionsList"
			:label="itemIndex == 0 ? 'Варианты атрибутов' : ''"
			prop="attribute_options_ids"
		>
			<!-- <label v-if="itemIndex == 0">UK</label> -->
			<el-select
				v-model="formData.attribute_options_ids"
				class="with-tags"
				multiple
				filterable
				placeholder="выберите атрибуты"
				:disabled="!additionalOptionsList.length"
			>
				<el-option
					v-for="item in additionalOptionsList"
					:key="'attribute_option_id-' + item.id"
					:label="item.title_ru"
					:value="item.id"
				/>
			</el-select>
		</el-form-item>

		<el-button
			class="action-button"
			size="mini"
			type="danger"
			icon="icomoon icon-cross"
			@click="removeItem"
		/>
	</el-form>
</template>

<script>
import { required } from '@/constants/validation';
import { subItemFormMixin } from '@/mixins';

export default {
	mixins: [subItemFormMixin],
	props: {
		additionalOptionsList: {
			type: Array
		},
		formDataProp: String
	},

	data() {
		return {
			formData: {
				id: null,
				title_ru: '',
				title_uk: '',
				attribute_options_ids: []
			}
		};
	},

	computed: {
		rules: () => ({
			title_uk: required,
			title_ru: required
		}),
		targetPropName: () => 'attribute_options'
	},

	methods: {
		localSetupPageActions(itemData) {
			if (itemData.attribute_options) {
				this.formData.attribute_options_ids = [];

				for (const option of itemData.attribute_options) {
					this.formData.attribute_options_ids.push(option.id);
				}
			}
		},
	}
};
</script>
